<template>
	<div class="loginWrap" id="wrap">
		<h1>INSITENOTE</h1>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2>로그인</h2>
			</div>
		</div>
		<!--// titArea -->
		<!-- loginArea -->
		<div class="loginArea">
			<ul>
				<li>
					<input
						type="text"
						title="아이디 입력"
						placeholder="이메일 로그인"
						required="required"
						v-model="loginId"
						@keyup.enter="loginBtn ? onLogin() : null"
						ref="id"
						maxlength="50"
					/>
				</li>
				<li>
					<input
						:type="passwordView ? 'text' : 'password'"
						title="비밀번호 입력"
						placeholder="비밀번호 (8자 ~ 20자)"
						required="required"
						minlength="8"
						maxlength="20"
						v-model="password"
						@keyup.enter="loginBtn ? onLogin() : null"
					/>
					<a :class="passwordView ? 'btnView' : 'btnView off'" @click="onChangePasswordView()">비밀번호 표시하기</a>
				</li>
				<li>
					<a class="btnTxt" @click="findId()">아이디 찾기</a>
					<a class="btnTxt" @click="findPassword()">비밀번호 찾기</a>
				</li>
			</ul>
			<!-- btnArea -->
			<div class="btnArea">
				<button type="button" class="btn" @click="onLogin()" :disabled="!loginBtn">로그인</button>
				<button type="button" class="btn btnWhite" @click="register()">회원가입</button>
			</div>
			<!--// btnArea -->
		</div>
		<!--// loginArea -->
	</div>
</template>

<script>
import auth from '@/api/auth';
import user from '@/api/user';
import { showSpinner, hideSpinner } from '@/utils/cmm';

export default {
	data() {
		return {
			loginId: null,
			password: null,
			passwordView: false,
			isGoogleReq: false,
		};
	},
	created() {},
	mounted() {
		this.$refs.id.focus();
	},
	computed: {
		loginBtn() {
			return this.loginId && this.password;
		},
	},
	methods: {
		async onLogin() {
			showSpinner();
			try {
				await auth.login(this.loginId, this.password);
			} catch (ex) {
				if (ex.status === 2200) {
					if (
						!(await this.$confirm(
							'현재 탈퇴요청 중입니다. <br/> 확인을 누르시면 탈퇴 요청을 철회하고 <br/> 로그인 하실수 있습니다. <br/> 철회하시겠습니까?',
							'회원 안내',
						))
					) {
						return false;
					} else {
						await user.resignWithdraw(this.loginId, this.password);
						await auth.login(this.loginId, this.password);
					}
				} else {
					return await this.$alert(`아이디 또는 비밀번호가 잘못 입력 되었습니다. 정확히 입력해주세요.`, '회원 안내');
				}
			} finally {
				hideSpinner();
			}

			this.$router.push({ name: 'buildingList' });
		},
		register() {
			this.$router.push({ name: 'register' });
		},
		findId() {
			this.$router.push({ name: 'findId' });
		},
		findPassword() {
			this.$router.push({ name: 'findPassword' });
		},
		onChangePasswordView() {
			this.passwordView ? (this.passwordView = false) : (this.passwordView = true);
		},
	},
};
</script>

<style scoped></style>
